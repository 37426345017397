///* instagram */
///***********************************************/
.instagram {
  $self: &;

  &__item {
    &:before {
      content: '';
      display: block;
      padding-top: 67%;
    }

    &-img {
      top: 0;
      left: 0;
    }
  }
}
