///* features */
///***********************************************/
.features {
  $self: &;
  margin-top: -60px;
  @include mq(lg) {
    margin-top: -112px;
  }

  &__item {
    background-color: $black;

    &:before {
      content: '';
      display: block;
      padding-top: 113%;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#426dff, .4);
      opacity: 0;
      transition: $transition-fade;
    }

    &-cover {
      top: 0;
      transition: $transition-fade;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &-title {
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      right: 20px;
      z-index: 1;
    }

    &-button {
      bottom: 30px;
      left: 40px;
      right: 40px;
      z-index: 1;
    }
  }

  &__content {
    //margin-top: 70px;
  }
}
