.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display:none;
  width: 40px;
  height: 40px;
  align-items: center;
  color: $gray-600;
  z-index: 200;

  svg {
    margin-top: 10px;
  }
}
