///* courses */
///***********************************************/
.courses {
  $self: &;
  @include mq(lg) {
    min-height: 800px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../img/kurs.jpg'), #769af8;
    background-position: 50% 50%;
    background-size: cover;
    background-blend-mode: multiply;
    background-attachment: fixed;
    opacity: .9;
  }

  &__item {

    &:before {
      content: '';
      display: block;
      padding-top: 106%;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#426dff, .4);
      opacity: 0;
      transition: $transition-fade;
    }

    &-cover {
      top: 0;
      left: 0;
      opacity: .95;
      transition: $transition-fade;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &-button {
      z-index: 1;
      bottom: 80px;
      left: 40px;
      right: 40px;
    }
  }

}
