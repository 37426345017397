body, .navbar {
  min-width: 320px;
}

// for scaling font sizes
* {
  --ssfz: calc(var(--minfz) * 1px);
}

@include mq(#{$ss-start-step-font-scale}) {
  * {
    --ssfz: calc(((var(--maxfz) - var(--minfz)) / (#{($_font-scale-var-max-width - $_font-scale-var-min-width) / 100 })) * 1vw + (var(--minfz) - ((var(--maxfz) - var(--minfz)) / (#{$_font-scale-var-max-width - $_font-scale-var-min-width})) * #{$_font-scale-var-min-width}) * 1px);
  }
}

@include mq(#{$ss-end-step-font-scale}){
  * {
    --ssfz: calc(var(--maxfz) * 1px);
  }
}

///* button */
///***********************************************/
.btn-theme {
  @include button-variant(#1a2845, #1a2845, #0e3993, #0e3993, #022872);
}

// border
.border-2 {
  border-width: 2px !important;
}
